import React, { Fragment } from "react";
import { useTable, usePagination } from "react-table";
import { Link } from "react-router-dom";

const HistoryTable = ({ data, columns }) => {
  // combine column and data
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance;
  return (
    <div>
      <table {...getTableProps()} className="user-list-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={{ textAlign: "center" }}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td>{row.original.timestamp}</td>
                <td style={{ textAlign: "center" }}>
                  {row.original.position === "USER" ? (
                    <span style={{ color: "black" }}>ผู้ใช้งาน</span>
                  ) : row.original.position === "ADMIN" ? (
                    <span style={{ color: "black" }}>ผู้ดูแลระบบ</span>
                  ) : row.original.position === "COMMITTEE" ? (
                    <span style={{ color: "black" }}>กรรมการ</span>
                  ) : (
                    row.original.position
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  {row.original.active === "INACTIVE" ? (
                    <span style={{ color: "red" }}>ปิดการใช้งาน</span>
                  ) : row.original.active === "ACTIVE" ? (
                    <span style={{ color: "green" }}>ใช้งาน</span>
                  ) : (
                    "ปิดการใช้งาน"
                  )}
                </td>
                <td>{row.original.details}</td>
                <td>{row.original.user_order}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </button>
        <span>
          Page{" "}
          <strong>
            {state.pageIndex + 1} of {state.pageCount}
          </strong>{" "}
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </button>
      </div>
      <br />
    </div>
  );
};

export default HistoryTable;
