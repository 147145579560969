import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// action
import { verifyUser } from "../../actions/registerAction";

// reducer
import {
  setSuccessVerifyUser,
  resetVerifyUser,
} from "../../reducers/authReducer";

// json
import titlesData from "../../config/json/title.json";
import academicData from "../../config/json/academicStanding.json";

const VerifyUser = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const verificationToken = searchParams.get("token");

  const { msg, successVerify } = useSelector((state) => state.authState);
  const [rejectMessage, setRejectMessage] = useState(null);

  //   set form
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    title: "",
    firstname: "",
    lastname: "",
    phone: "",
    academic_standing: "NONE",
  });
  // title data
  const [titles, setTitles] = useState([]);
  useEffect(() => {
    // Load titles from titles.json
    setTitles(titlesData.titles);
  }, []);
  // error
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(""); // New error state

  // onChange
  const handleInputChange = (e) => {
    const value = e.target.value;
    const fieldName = e.target.name;
    const alphanumericPattern = /^[a-zA-Z0-9\s]*$/;

    // Handle username field separately
    if (fieldName === "username") {
      const trimmedUsername = value.trim().toLowerCase();
      setFormData({
        ...formData,
        [fieldName]: trimmedUsername,
      });

      // Prevent users from entering an email address in the username field
      if (trimmedUsername.includes("@")) {
        setUsernameError("อีเมลไม่สามารถเป็นชื่อผู้ใช้งานใช้ได้");
      } else if (!alphanumericPattern.test(trimmedUsername)) {
        setUsernameError("โปรดใส่เฉพาะตัวอักษรภาษาอังกฤษเท่านั้น");
      } else {
        setUsernameError("");
      }
    }
    // Handle password validation
    else if (fieldName === "password") {
      const passwordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordPattern.test(value)) {
        setPasswordError(
          "รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษรพิมพ์ใหญ่ ตัวอักษรพิมพ์เล็ก ตัวเลข และอักขระพิเศษ (@$!%*?&)"
        );
      } else {
        setPasswordError("");
      }
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
    // Handle phone number validation (numbers only)
    else if (fieldName === "phone") {
      if (!/^\d+$/.test(value)) {
        setPhoneNumberError("โปรดใส่เฉพาะตัวเลข");
      } else {
        setPhoneNumberError("");
      }
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
    // Handle other fields (without specific validation)
    else {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log({ ...formData, verificationToken });
    dispatch(verifyUser({ ...formData, verificationToken }));
  };

  // when start form
  const [startVerfity, setStartVerify] = useState(true);
  useEffect(() => {
    if (startVerfity) {
      dispatch(resetVerifyUser());
      setStartVerify(false);
    }
  }, [startVerfity]);

  // when success send
  // useEffect to handle the successVerify change
  useEffect(() => {
    if (successVerify) {
      // Show success modal
      toggleSuccessModal();
      // Reset success state to trigger only once
      dispatch(setSuccessVerifyUser(false));
    }

    if (msg) {
      setRejectMessage(msg);
    }

    if (rejectMessage) {
      // Show error modal
      toggleErrorModal();
      // Reset reject message
      // dispatch(setReject(null));
    }
  }, [dispatch, rejectMessage, msg]);

  // modal state
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  const handleSuccessModalOk = () => {
    // Navigate to "/"
    history("/");
    // window.location.reload();
  };

  const handleErrorModalOk = () => {
    // Close the error modal
    setErrorModalOpen(false);
  };

  return (
    <Fragment>
      <Container className="mt-4">
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody>
              <h4 className="text-center mb-4">ลงทะเบียน - ผู้ใช้งาน</h4>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="username">ชื่อผู้ใช้งาน (username)</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    autoComplete="username"
                    required
                  />
                  {usernameError && (
                    <small className="text-danger">{usernameError}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="password">รหัสผ่าน</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    autoComplete="current-password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                    title="Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit, and one special character (@$!%*?&)."
                    required
                  />
                  {passwordError && (
                    <small className="text-danger">{passwordError}</small>
                  )}
                  <small className="text-muted">
                    รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร
                    ประกอบด้วยตัวอักษรพิมพ์ใหญ่ (A-Z), ตัวอักษรพิมพ์เล็ก (a-z),
                    ตัวเลข (0-9) และอักขระพิเศษ (@$!%*?&)
                  </small>
                </FormGroup>
                <FormGroup>
                  <Label for="title">คำนำหน้า</Label>
                  <Input
                    type="select"
                    name="title"
                    id="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      โปรดเลือก
                    </option>
                    {titles.map((title) => (
                      <option key={title} value={title}>
                        {title}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="firstname">ชื่อจริง</Label>
                  <Input
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastname">นามสกุล</Label>
                  <Input
                    type="text"
                    name="lastname"
                    id="lastname"
                    value={formData.lastname}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phone">เบอร์โทรศัพท์</Label>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    pattern="\d*"
                    title="โปรดใส่เฉพาะตัวเลข"
                    required
                  />
                  {phoneNumberError && (
                    <small className="text-danger">{phoneNumberError}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="academic_standing">ตำแหน่งทางวิชาการ</Label>
                  <Input
                    type="select"
                    name="academic_standing"
                    id="academic_standing"
                    value={formData.academic_standing}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="NONE">ไม่มี</option>
                    {academicData.academic_standing.map((standing) => (
                      <option key={standing.value} value={standing.value}>
                        {standing.name_th}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <Button type="submit" color="primary" block>
                  ลงทะเบียน
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Container>
      <br />
      {/* Success Modal */}
      <Modal isOpen={successModalOpen} toggle={toggleSuccessModal}>
        <ModalHeader toggle={toggleSuccessModal}>Verify Successful</ModalHeader>
        <ModalBody>
          Your verify was successful! Click "OK" to continue.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSuccessModalOk}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      {/* Error Modal */}
      <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
        <ModalHeader toggle={toggleErrorModal}>Verify Error</ModalHeader>
        <ModalBody>
          {rejectMessage} {/* Display the error message */}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleErrorModalOk}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default VerifyUser;
