import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { resetPassword } from "../../actions/authAction";
import { resetResetPassword } from "../../reducers/authReducer";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const user = urlParams.get("user");
  const token = urlParams.get("token");

  const [username, setUsername] = useState(user || "error");
  const { resetPassword: resetPState, msg } = useSelector(
    (state) => state.authState
  );

  useEffect(() => {
    dispatch(resetResetPassword());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmedPassword) {
      setPasswordsMatch(false);
      return;
    }

    const data = { username, token, password };
    dispatch(resetPassword(data));
    setSubmitted(true);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <CardHeader>รีเซ็ตรหัสผ่าน</CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                {submitted ? (
                  resetPState ? (
                    <p>เปลี่ยนรหัสผ่านสำเร็จ โปรดกลับหน้าหลัก</p>
                  ) : (
                    <p>โปรดรอระบบตอบกลับ....</p>
                  )
                ) : (
                  <>
                    <FormGroup>
                      <Label for="username">ชื่อผู้ใช้งาน:</Label>
                      <Input
                        type="text"
                        id="username"
                        value={username}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">รหัสผ่านใหม่:</Label>
                      <Input
                        type="password"
                        id="password"
                        placeholder="กรอกรหัสผ่านใหม่"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="new-password"
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                        title="รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร และต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่ ตัวอักษรพิมพ์เล็ก ตัวเลข และสัญลักษณ์พิเศษอย่างน้อย 1 ตัว"
                        required
                        style={{ borderColor: passwordsMatch ? "" : "red" }}
                      />
                      <small className="form-text text-muted">
                        รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร
                        ประกอบด้วยตัวอักษรพิมพ์ใหญ่ พิมพ์เล็ก ตัวเลข
                        และสัญลักษณ์พิเศษ (@$!%*?&) อย่างน้อย 1 ตัว
                      </small>
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirmedPassword">ยืนยันรหัสผ่าน:</Label>
                      <Input
                        type="password"
                        id="confirmedPassword"
                        placeholder="ยืนยันรหัสผ่านใหม่"
                        value={confirmedPassword}
                        onChange={(e) => setConfirmedPassword(e.target.value)}
                        autoComplete="new-password"
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                        title="รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร และต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่ ตัวอักษรพิมพ์เล็ก ตัวเลข และสัญลักษณ์พิเศษอย่างน้อย 1 ตัว"
                        required
                        style={{ borderColor: passwordsMatch ? "" : "red" }}
                      />
                      {!passwordsMatch && (
                        <p style={{ color: "red" }}>รหัสผ่านไม่ตรงกัน</p>
                      )}
                    </FormGroup>
                    <Button
                      color="dark"
                      type="submit"
                      disabled={submitted}
                      block
                    >
                      {submitted ? "กำลังส่ง..." : "ยืนยัน"}
                    </Button>
                  </>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
