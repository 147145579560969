import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { registerAdmin } from "../../actions/registerAction";

import {
  resetRegisterAdmin,
  setSuccessRegisterAdmin,
} from "../../reducers/authReducer";

// json
import titlesData from "../../config/json/title.json";
import academicData from "../../config/json/academicStanding.json";

export default function RegisterAdmin() {
  const dispatch = useDispatch();
  const successRegisterAdmin = useSelector(
    (state) => state.authState.successRegisterAdmin
  );
  const { msg } = useSelector((state) => state.authState);

  const [rejectMessage, setRejectMessage] = useState(null);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    title: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    academic_standing: "NONE",
    adminkey: "",
  });
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(""); // New error state

  // title data
  const [titles, setTitles] = useState([]);
  useEffect(() => {
    // Load titles from titles.json
    setTitles(titlesData.titles);
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const fieldName = e.target.name;
    const alphanumericPattern = /^[a-zA-Z0-9\s]*$/;

    // Prevent users from entering an email address in the username field
    if (e.target.name === "username" && e.target.value.includes("@")) {
      setUsernameError("อีเมลไม่สามารถเป็นชื่อผู้ใช้งานใช้ได้");
    } else {
      setUsernameError(""); // Clear the error message if the condition is not met
    }

    // Validate English characters for Username, Password, and Email
    if (fieldName === "username" && !alphanumericPattern.test(value)) {
      setUsernameError("โปรดใส่เฉพาะตัวอักษรภาษาอังกฤษเท่านั้น");
    } else {
      setUsernameError("");
    }

    if (fieldName === "password") {
      const passwordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordPattern.test(value)) {
        setPasswordError(
          "รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษรพิมพ์ใหญ่ ตัวอักษรพิมพ์เล็ก ตัวเลข และอักขระพิเศษ (@$!%*?&)"
        );
      } else {
        setPasswordError("");
      }
    }

    if (fieldName === "email" && !/^[a-zA-Z0-9@.]*$/.test(value)) {
      setEmailError(
        "โปรดใส่เฉพาะอีเมลที่มีตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น"
      );
    } else {
      setEmailError("");
    }

    if (fieldName === "phone" && !/^\d+$/.test(value)) {
      setPhoneNumberError("โปรดใส่เฉพาะตัวเลข");
    } else {
      setPhoneNumberError("");
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(registerAdmin(formData));
  };

  // start register admin form
  const [startAdmin, setStartAdmin] = useState(true);
  useEffect(() => {
    if (startAdmin) {
      dispatch(resetRegisterAdmin());
      setStartAdmin(false);
    }
  }, [startAdmin]);

  // useEffect to handle the successRegisterAdmin change
  useEffect(() => {
    if (successRegisterAdmin) {
      // Show success modal
      toggleSuccessModal();
      // Reset success state to trigger only once
      dispatch(setSuccessRegisterAdmin(false));
    }

    if (msg) {
      setRejectMessage(msg);
    }

    if (rejectMessage) {
      // Show error modal
      toggleErrorModal();
      // Reset reject message
      // dispatch(setReject(null));
    }
  }, [successRegisterAdmin, dispatch, rejectMessage, msg]);

  // Modal state
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  const handleSuccessModalOk = () => {
    // Navigate to "/"
    window.location.reload();
  };

  const handleErrorModalOk = () => {
    // Close the error modal
    setErrorModalOpen(false);
  };

  return (
    <Fragment>
      <Container className="mt-4">
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardBody>
              <h4 className="text-center mb-4">ลงทะเบียน - ผู้ดูแลระบบ</h4>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="username">ชื่อผู้ใช้งาน (username)</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    autoComplete="username"
                    required
                  />
                  {usernameError && (
                    <small className="text-danger">{usernameError}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="password">รหัสผ่าน</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    autoComplete="current-password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                    title="Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one digit, and one special character (@$!%*?&)."
                    required
                  />
                  {passwordError && (
                    <small className="text-danger">{passwordError}</small>
                  )}
                  <small className="text-muted">
                    รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร
                    ประกอบด้วยตัวอักษรพิมพ์ใหญ่ (A-Z), ตัวอักษรพิมพ์เล็ก (a-z),
                    ตัวเลข (0-9) และอักขระพิเศษ (@$!%*?&)
                  </small>
                </FormGroup>
                <FormGroup>
                  <Label for="title">คำนำหน้า</Label>
                  <Input
                    type="select"
                    name="title"
                    id="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      โปรดเลือก
                    </option>
                    {titles.map((title) => (
                      <option key={title} value={title}>
                        {title}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="firstname">ชื่อจริง</Label>
                  <Input
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastname">นามสกุล</Label>
                  <Input
                    type="text"
                    name="lastname"
                    id="lastname"
                    value={formData.lastname}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">อีเมล</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  {emailError && (
                    <small className="text-danger">{emailError}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="phone">เบอร์โทรศัพท์</Label>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    pattern="\d*"
                    title="โปรดใส่เฉพาะตัวเลข"
                    required
                  />
                  {phoneNumberError && (
                    <small className="text-danger">{phoneNumberError}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="academic_standing">ตำแหน่งทางวิชาการ</Label>
                  <Input
                    type="select"
                    name="academic_standing"
                    id="academic_standing"
                    value={formData.academic_standing}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="NONE">ไม่มี</option>
                    {academicData.academic_standing.map((standing) => (
                      <option key={standing.value} value={standing.value}>
                        {standing.name_th}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="adminkey">รหัสผู้ดูแลระบบ</Label>
                  <Input
                    type="text"
                    name="adminkey"
                    id="adminkey"
                    value={formData.adminkey}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <Button type="submit" color="primary" block>
                  ลงทะเบียน
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Container>
      <br />
      {/* Success Modal */}
      <Modal isOpen={successModalOpen} toggle={toggleSuccessModal}>
        <ModalHeader toggle={toggleSuccessModal}>
          Registration Successful
        </ModalHeader>
        <ModalBody>
          Your registration was successful! Click "OK" to continue.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSuccessModalOk}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      {/* Error Modal */}
      <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
        <ModalHeader toggle={toggleErrorModal}>Registration Error</ModalHeader>
        <ModalBody>
          {rejectMessage} {/* Display the error message */}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleErrorModalOk}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}
