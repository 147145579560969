import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Row,
  Container,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import classnames from "classnames";

import ServiceForm from "./ServiceForm";
import ServiceHistory from "./History";
import NotAllow from "../Error/notAllow";

import { resetService } from "../../reducers/serviceReducer";
import { getHistory } from "../../actions/serviceAction";

import "./ServiceList.css";

export default function ServiceList() {
  let content;
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const selectedUserName = params.get("username");

  const {
    token: authToken,
    username,
    firstname,
    lastname,
    position,
  } = useSelector((state) => state.authState);
  const userList = useSelector((state) => state.userState.userlist);

  // Find the user from userList using username
  const selectedUser = userList.find(
    (user) => user.username === selectedUserName
  );

  const [loadForm, setLoadForm] = useState(false);
  const [activeTab, setActiveTab] = React.useState("e-service");

  //   useEffect
  // reset reducer
  useEffect(() => {
    if (loadForm === false) {
      dispatch(resetService());
      setLoadForm(true);
    }
  }, [loadForm]);
  // get history data
  useEffect(() => {
    const sendData = {
      accessToken: authToken.accessToken,
      refreshToken: authToken.refreshToken,
      username: selectedUserName,
      service: activeTab,
    };
    dispatch(getHistory(sendData));
  }, [activeTab]);

  // const
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const getServiceData = (serviceName) => {
    return selectedUser?.service.find((s) => s.name === serviceName) || {};
  };

  //   return
  if (position === "ADMIN" && selectedUser) {
    content = (
      <Fragment>
        <br />
        <Container>
          <h2 className="servicelist-header">สิทธิการเข้าใช้งาน</h2>
        </Container>
        {/* Breadcrumb */}
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="/">หน้าหลัก</a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href="/userlist">รายชื่อผู้ใช้งาน</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>สิทธิการเข้าใช้งาน</BreadcrumbItem>
          </Breadcrumb>
        </Container>

        {/* info */}
        <Container>
          <Row>
            <Col>
              <br />
              <div className="user-info">
                <p>
                  <strong>Name:</strong>{" "}
                  {`${selectedUser.title} ${selectedUser.firstname} ${selectedUser.lastname}`}
                </p>
                <p>
                  <strong>Username:</strong> {selectedUser.username}
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        {/* service */}
        <Container>
          <br />
          <Row>
            <Col>
              <h3 className="service-header">ข้อมูลระบบบริการ</h3>
              <Nav tabs className="service-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "e-service",
                    })}
                    onClick={() => {
                      toggle("e-service");
                    }}
                  >
                    E-Service
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "e-finance",
                    })}
                    onClick={() => {
                      toggle("e-finance");
                    }}
                  >
                    E-Finance
                  </NavLink>
                </NavItem>
              </Nav>

              {/* tab info service */}
              <TabContent activeTab={activeTab} className="service-tab-content">
                <TabPane tabId="e-service">
                  {/* Form */}
                  <ServiceForm
                    serviceData={selectedUser}
                    serviceType="e-service"
                  />
                  <br />
                  {/* History */}
                  <ServiceHistory serviceType="e-service" />
                </TabPane>

                <TabPane tabId="e-finance">
                  {/* Form */}
                  <ServiceForm
                    serviceData={getServiceData("e-finance")}
                    serviceType="e-finance"
                  />
                  <br />
                  {/* History */}
                  <ServiceHistory serviceType="e-finance" />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>

        {/* history changed */}
      </Fragment>
    );
  } else if (position === "ADMIN") {
    content = (
      <Fragment>
        <br />
        <Container>
          <Row>
            <Col>User not found.</Col>
          </Row>
        </Container>
      </Fragment>
    );
  } else {
    content = <NotAllow />;
  }
  return content;
}
