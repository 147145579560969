import React from "react";
import CookieConsent from "react-cookie-consent";

export const Footer = () => {
  return (
    <div className="row m-0">
      <div
        // className="bg-light"
        style={{
          textAlign: "center",
          verticalAlign: "middle",
          backgroundColor: "#ccc",
        }}
      >
        <p className="pt-3">ฝ่ายเทคโนโลยีสารสนเทศ โรงเรียนวัดพลงช้างเผือก</p>
        <CookieConsent
          buttonClasses="btn btn-primary"
          location="bottom"
          contentClasses="text-capitalize"
          buttonText="ปิด"
        >
          ทางโรงเรียนมีการใช้งานคุกกี้ (Cookies)
          เพื่อจัดการข้อมูลส่วนบุคคลและช่วยเพิ่มประสิทธิภาพการใช้งานเว็บไซต์
          ท่านสามารถศึกษารายละเอียดเพิ่มเติมและการตั้งค่าคุกกี้ได้ที่{" "}
          <a href="#" target="_blank" rel="noreferrer">
            นโยบายการใช้คุ้กกี้
          </a>
        </CookieConsent>
      </div>
    </div>
  );
};
