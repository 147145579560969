import React, { Fragment } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CardUtilis = ({ imageMain, titleUtilis, textUtilis, pathUtilis }) => {
  const imgStyle = {
    maxWidth: 300,
  };
  const dispatch = useDispatch();
  const history = useNavigate();

  const onClick = async (e) => {
    e.preventDefault();
    await history(pathUtilis);
  };

  return (
    <Fragment>
      <Card>
        <br />
        <CardImg
          top
          className="align-self-center"
          width="100%"
          src={imageMain}
          style={imgStyle}
          alt="Card image cap"
        />
        <CardBody>
          <CardTitle>{titleUtilis}</CardTitle>
          <CardText>{textUtilis}</CardText>
          <Button color="dark" onClick={onClick} block>
            เข้าใช้งาน
          </Button>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CardUtilis;
