import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../config/config";
import apiHelper from "../config/helper/apiHelper";
import axios from "axios";

const HOST_API = config.HOST_API;

// get user list
export const getUserList = createAsyncThunk(
  "user/list",
  async (data, { rejectWithValue }) => {
    try {
      const accessToken = data.accessToken;
      const refreshToken = data.refreshToken;
      const username = data.username;

      const url = `${HOST_API}/api/userlist/get`;
      const method = "POST";
      const body = JSON.stringify({
        refreshToken,
        username,
      });

      //   console.log(body);
      const headers = {
        "Content-Type": "application/json",
        Authorization: accessToken,
      };

      const result = await apiHelper(url, method, body, headers);
      // console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);
