import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
// json
import positions from "../../config/json/positions.json";
// action
import { updateService } from "../../actions/serviceAction";

const ServiceForm = ({ serviceData, serviceType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const selecteduserName = params.get("username");

  const { token: authToken, username } = useSelector(
    (state) => state.authState
  );
  const { serviceUpdateStatus, isLoading } = useSelector(
    (state) => state.serviceState
  );
  const userList = useSelector((state) => state.userState.userlist);
  // Find the user from userList using username
  const selectedUser = userList.find(
    (user) => user.username === selecteduserName
  );

  const [active, setActive] = useState(serviceData?.active || "INACTIVE");
  const [email, setEmail] = useState("");

  const defaultPosition = positions[serviceType][0];
  const [position, setPosition] = useState(
    serviceData?.position || defaultPosition.value
  );
  const [updated, setUpdated] = useState(false);

  // useEffect
  useEffect(() => {
    if (serviceUpdateStatus === true) {
      setUpdated(true);
    }
    // console.log(serviceData);
  }, [serviceUpdateStatus]);
  useEffect(() => {
    if (serviceType === "e-service") {
      setEmail(serviceData?.email || "");
    } else if (serviceType === "e-finance") {
      setEmail(selectedUser.email);
    }
  }, [serviceType]);

  // const
  const sendUpdate = async () => {
    if (serviceType === "e-finance") {
      let newData = {
        accessToken: authToken.accessToken,
        refreshToken: authToken.refreshToken,
        username: selecteduserName,
        email: email,
        service: serviceType,
        position: position,
        active: active,
        user_order: username,
      };
      dispatch(updateService(newData));
    } else if (serviceType === "e-service") {
      let newData = {
        accessToken: authToken.accessToken,
        refreshToken: authToken.refreshToken,
        username: selecteduserName,
        email: email,
        service: serviceType,
        position: position,
        active: active,
        user_order: username,
      };
      dispatch(updateService(newData));
    }
  };

  const backtoListUser = async () => {
    navigate("/userlist");
  };

  return (
    <div>
      <Modal isOpen={updated}>
        <ModalHeader>Permission Update for Service Successful</ModalHeader>
        <ModalBody>อัพเดตข้อมูลสำเร็จ โปรดกลับหน้ารายชื่อผู้ใช้งาน</ModalBody>
        <ModalFooter>
          <Button onClick={backtoListUser}>ตกลง</Button>
        </ModalFooter>
      </Modal>

      <Container>
        <h5>
          <u>รายละเอียด</u>
        </h5>
        <Form>
          <FormGroup>
            <Label for="active">การเปิดใช้งาน</Label>
            <Input
              type="select"
              name="active"
              value={active}
              onChange={(e) => setActive(e.target.value)}
            >
              <option value="ACTIVE">เปิดการใช้งาน</option>
              <option value="INACTIVE">ปิดการใช้งาน</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="position">ตำแหน่ง</Label>
            <Input
              type="select"
              name="position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            >
              {positions[serviceType].map((pos) => (
                <option key={pos.value} value={pos.value}>
                  {pos.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          {isLoading ? (
            <Button disabled>กำลังเปลี่ยนแปลงข้อมูล...</Button>
          ) : (
            <Button onClick={sendUpdate}>ยืนยันการเปลี่ยนแปลง</Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default ServiceForm;
