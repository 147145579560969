import { createSlice } from "@reduxjs/toolkit";
import { checkAdmin } from "../actions/authAction";
import {
  registerAdmin,
  sendInvitations,
  verifyUser,
} from "../actions/registerAction";
import {
  forgotPassword,
  resetPassword,
  login,
  logout,
  getUser,
} from "../actions/authAction";

const initialState = {
  token: null,
  username: null,
  title: null,
  firstname: null,
  lastname: null,
  email: null,
  position: null,
  phone: null,
  academicStanding: null,
  active: null,
  service: [],
  msg: null,
  forgotPassword: false,
  resetPassword: false,
  registerAdmin: false,
  successRegisterAdmin: false,
  successInvitation: false,
  successVerify: false,
  isLoading: false,
  isReject: false,
};

const rejectState = {
  token: null,
  username: null,
  title: null,
  firstname: null,
  lastname: null,
  email: null,
  position: null,
  phone: null,
  academicStanding: null,
  active: null,
  service: [],
  forgotPassword: false,
  resetPassword: false,
  registerAdmin: false,
  successRegisterAdmin: false,
  successInvitation: false,
  successVerify: false,
  isLoading: false,
  isReject: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    resetAuth: () => initialState,
    setSuccessRegisterAdmin: (state, action) => {
      state.successRegisterAdmin = action.payload;
    },
    setSuccessVerifyUser: (state, action) => {
      state.successVerify = action.payload;
    },
    resetRegisterAdmin: (state) => {
      state.successRegisterAdmin = false;
      state.msg = null;
    },
    resetVerifyUser: (state) => {
      state.successVerify = false;
      state.msg = null;
    },
    resetSendEmail: (state) => {
      state.successInvitation = false;
      state.msg = null;
    },
    resetForgotPassword: (state) => {
      state.forgotPassword = false;
      state.msg = null;
    },
    resetResetPassword: (state) => {
      state.resetPassword = false;
      state.msg = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // checkadmin
      .addCase(checkAdmin.pending, (state) => {
        state.isLoading = true;
        state.isReject = false;
      })
      .addCase(checkAdmin.fulfilled, (state, action) => {
        state.registerAdmin =
          action.payload.adminUserCount === 0 ? true : false;
        state.isLoading = false;
        state.isReject = false;
      })
      .addCase(checkAdmin.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      })

      // registerAdmin
      .addCase(registerAdmin.pending, (state) => {
        state.isLoading = true;
        state.isReject = false;
      })
      .addCase(registerAdmin.fulfilled, (state, action) => {
        state.token = action.payload.tokens;
        state.username = action.payload.username;
        state.title = action.payload.title;
        state.firstname = action.payload.firstname;
        state.lastname = action.payload.lastname;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.academicStanding = action.payload.academic_standing;
        state.position = action.payload.position;
        state.active = action.payload.active;
        state.successRegisterAdmin = true;
        state.isLoading = false;
        state.isReject = false;
      })
      .addCase(registerAdmin.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      })

      // verify user
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
        state.isReject = false;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.token = action.payload.tokens;
        state.username = action.payload.username;
        state.title = action.payload.title;
        state.firstname = action.payload.firstname;
        state.lastname = action.payload.lastname;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.academicStanding = action.payload.academic_standing;
        state.position = action.payload.position;
        state.active = action.payload.active;
        state.successVerify = true;
        state.isLoading = false;
        state.isReject = false;
      })
      .addCase(verifyUser.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      })

      // send invitation
      .addCase(sendInvitations.pending, (state) => {
        state.isLoading = true;
        state.isReject = false;
      })
      .addCase(sendInvitations.fulfilled, (state) => {
        state.successInvitation = true;
        state.isLoading = false;
        state.isReject = false;
      })
      .addCase(sendInvitations.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      })

      // forgotPassword
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
        state.isReject = false;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.forgotPassword = true;
        state.isLoading = false;
        state.isReject = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      })

      // resetPassword
      .addCase(resetPassword.pending, (state) => {
        state.isReject = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPassword = true;
        state.isReject = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      })

      // login
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isReject = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.token = action.payload.tokens;
        state.username = action.payload.username;
        state.title = action.payload.title;
        state.firstname = action.payload.firstname;
        state.lastname = action.payload.lastname;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.academicStanding = action.payload.academic_standing;
        state.position = action.payload.position;
        state.active = action.payload.active;
        state.service = action.payload.service;
        state.isLoading = false;
        state.isReject = false;
      })
      .addCase(login.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      })

      // get user data
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
        state.isReject = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.token = action.payload.tokens;
        state.username = action.payload.username;
        state.title = action.payload.title;
        state.firstname = action.payload.firstname;
        state.lastname = action.payload.lastname;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.academicStanding = action.payload.academic_standing;
        state.position = action.payload.position;
        state.active = action.payload.active;
        state.service = action.payload.service;
        state.isLoading = false;
        state.isReject = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      })

      // logout
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.isReject = false;
      })
      .addCase(logout.fulfilled, (state) => {
        Object.assign(state, initialState);
      })
      .addCase(logout.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      });
  },
});

export const {
  setAuthUser,
  setLoading,
  resetAuth,
  resetRegisterAdmin,
  resetVerifyUser,
  resetForgotPassword,
  resetResetPassword,
  setSuccessRegisterAdmin,
  setSuccessVerifyUser,
  resetSendEmail,
} = authSlice.actions;
export default authSlice.reducer;
