import React, { useState, useEffect, Fragment } from "react";
import { config } from "../../config/config";
import { useSelector, useDispatch } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

// Card
import CardService from "./Service/CardService";
import CardUtilis from "./Utilis/CardUtilis";

// image
import ImgUserList from "../../images/PicUserSetting.png";
import ImgFinanceService from "../../images/PicFinance.png";

const FinanceClient = config.Finance_Client;

export default function Main() {
  const dispatch = useDispatch();
  const {
    token: authToken,
    username,
    position,
    active,
    service,
  } = useSelector((state) => state.authState);

  // Tab
  const [activeTab, setActiveTab] = React.useState("service");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // check service active?
  const [availableFinance, setAvailableFinance] = useState(false);
  useEffect(() => {
    const financeStatus = service.find(
      (service) => service.name === "e-finance"
    )?.active;

    setAvailableFinance(financeStatus !== "ACTIVE");
  }, [service]);

  // test
  useEffect(() => {
    console.log(availableFinance);
  }, [availableFinance]);

  return (
    <Fragment>
      <br />
      {/* link */}
      <Container>
        <Breadcrumb>
          <BreadcrumbItem active>หน้าหลัก</BreadcrumbItem>
        </Breadcrumb>
      </Container>

      {/* tabs */}
      <Container>
        <Nav tabs>
          {/* service tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "service",
              })}
              onClick={() => {
                toggle("service");
              }}
            >
              บริการออนไลน์
            </NavLink>
          </NavItem>
          {/* utilis tab */}
          {position === "ADMIN" && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "utilis",
                })}
                onClick={() => {
                  toggle("utilis");
                }}
              >
                เครื่องมือ / การตั้งค่า
              </NavLink>
            </NavItem>
          )}
        </Nav>

        {/* tab content */}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="service">
            <br />
            <Row>
              <h5>รายการบริการออนไลน์</h5>
            </Row>
            <Row xs="1" sm="2" md="3">
              {/* E-Finance */}
              <Col>
                <CardService
                  imageMain={ImgFinanceService}
                  titleService="การเบิกและจัดสรรงบประมาณ"
                  textService="เบิก, โอน, ติดตาม และสรุปผลงบประมาณภายในโรงเรียน"
                  urlService={FinanceClient}
                  disableService={availableFinance}
                  token={authToken}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="utilis">
            <br />
            <Row>
              <h5>รายการเครื่องมือ/การตั้งค่า</h5>
            </Row>
            <Row xs="1" sm="2" md="3">
              {/* userlist */}
              {position === "ADMIN" && (
                <Fragment>
                  <Col>
                    <CardUtilis
                      imageMain={ImgUserList}
                      titleUtilis="จัดการผู้ใช้งาน"
                      textUtilis="จัดการข้อมูลผู้ใช้งาน - ตรวจสอบและแก้ไขข้อมูลผู้ใช้งาน"
                      pathUtilis="/userlist"
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  );
}
