import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../config/config";
import apiHelper from "../config/helper/apiHelper";
import axios from "axios";

const HOST_API = config.HOST_API;

// Setup headers with token
export const setToken = (token) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["x-auth-token"] = token;
  }
  return headers;
};

// get user data
export const getUser = createAsyncThunk(
  "auth/user",
  async (data, { rejectWithValue }) => {
    try {
      const accessToken = data.accessToken;
      const refreshToken = data.refreshToken;
      const username = data.username;

      const url = `${HOST_API}/api/auth/user-data`;
      const method = "POST";
      const body = JSON.stringify({
        refreshToken,
        username,
      });

      // console.log(data);
      const headers = {
        "Content-Type": "application/json",
        Authorization: accessToken,
      };

      const result = await apiHelper(url, method, body, headers);
      // console.log(result);
      return result;
    } catch (error) {
      // console.log("error: " + error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);

// login
export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    const headers = {
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(data);
    // console.log(body);

    try {
      const response = await axios.post(`${HOST_API}/api/auth/login`, body, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);

// get number admin
export const checkAdmin = createAsyncThunk("auth/checkadmin", async () => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(`${HOST_API}/api/auth/checkadminusers`, {
      headers,
    });
    if (response.data) {
      const dataCheckAdmin = response.data;
      // console.log(dataCheckAdmin);
      return dataCheckAdmin;
    }
  } catch (error) {
    console.error("CheckAdminError: ", error);
  }
});

// forgot password
export const forgotPassword = createAsyncThunk(
  "auth/forgot-password",
  async (email, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(email);
      // console.log(body);

      const response = await axios.post(
        `${HOST_API}/api/auth/forgot-password`,
        body,
        { headers }
      );
      return response.data;
    } catch (error) {
      // console.log(error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);

// reset password
export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (data, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(data);
      console.log(body);

      // send api
      const response = await axios.post(
        `${HOST_API}/api/auth/reset-password`,
        body,
        { headers }
      );
      // return
      return response.data;
    } catch (error) {
      console.log(error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);

// logout
export const logout = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(data);
      // console.log(body);

      // send api
      const response = await axios.post(`${HOST_API}/api/auth/logout`, body, {
        headers,
      });
      // return
      return response.data;
    } catch (error) {
      console.log(error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);
