import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./App.css";

// components
import { Footer } from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import LoadingScreen from "./components/Main/LoadingScreen";
import AppRoutes from "./components/AppRoutes"; // Import AppRoutes

// actions
import { checkAdmin, getUser } from "./actions/authAction";

// reducers
import { resetAuth } from "./reducers/authReducer";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    token: authToken,
    username,
    position,
    active,
    registerAdmin,
    isLoading: authLoading,
  } = useSelector((state) => state.authState);

  const [startPage, setStartPage] = useState(true);
  const [delayPassed, setDelayPassed] = useState(false);
  const [shouldShowLoadingScreen, setShouldShowLoadingScreen] = useState(false);

  useEffect(() => {
    dispatch(checkAdmin());
    if (username && authToken && startPage) {
      const { accessToken, refreshToken } = authToken;
      if (accessToken && refreshToken) {
        dispatch(getUser({ username, accessToken, refreshToken }));
        setStartPage(false);
      }
    }
  }, [dispatch, registerAdmin, username, authToken, startPage]);

  useEffect(() => {
    if (authLoading) {
      setShouldShowLoadingScreen(true);
      setDelayPassed(false);
    } else if (!authLoading && !delayPassed) {
      const timer = setTimeout(() => {
        setDelayPassed(true);
        setShouldShowLoadingScreen(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [authLoading, delayPassed]);

  const handleResetAndNavigate = () => {
    dispatch(resetAuth());
    navigate("/login");
  };

  return shouldShowLoadingScreen ? (
    <LoadingScreen resetState={handleResetAndNavigate} />
  ) : (
    <>
      <Navbar />
      <br />
      <AppRoutes
        authToken={authToken}
        active={active}
        registerAdmin={registerAdmin}
        position={position}
        username={username}
      />
      <br />
      <Footer />
    </>
  );
}

export default App;
