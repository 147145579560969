import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Media,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

// actions and reducers
import { login } from "../../actions/authAction";
import { resetAuth } from "../../reducers/authReducer";

import logo from "../../images/logo.png";

export default function Login() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usernameError, setUsernameError] = useState("");

  const { msg } = useSelector((state) => state.authState);
  const [message, setMessage] = useState(msg);

  // Update error message and open modal when there's an error
  useEffect(() => {
    if (msg) {
      setMessage(
        msg === "Invalid credentials"
          ? "username หรือ password ไม่ถูกต้อง"
          : msg
      );
      setIsModalOpen(true);
    }
  }, [msg]);

  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(resetAuth());
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
  };

  const handleToForgotPassword = () => {
    history("/forgot-password");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // Convert username to lowercase and validate
    const trimmedUsername = username.trim().toLowerCase();
    const usernamePattern = /^[a-z0-9]+$/; // Only allow lowercase English letters and numbers

    if (!usernamePattern.test(trimmedUsername)) {
      setUsernameError(
        "Username ต้องเป็นตัวอักษรภาษาอังกฤษตัวเล็กหรือตัวเลขเท่านั้น"
      );
      return;
    }

    setUsernameError(""); // Clear any previous errors

    // Dispatch the login action with the processed username
    dispatch(login({ username: trimmedUsername, password }));
  };

  return (
    <Fragment>
      <Container>
        <Row className="justify-content-center">
          <Media
            object
            src={logo}
            style={{ maxWidth: "400px" }}
            alt="Logo"
            className="mb-4"
          />
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md="8" lg="6">
            <Card
              className="shadow"
              style={{ borderRadius: "10px", padding: "20px" }}
            >
              <CardHeader
                className="text-center"
                style={{
                  backgroundColor: "#3b3b3b",
                  color: "white",
                  borderRadius: "10px 10px 0 0",
                  fontSize: "1.5rem",
                  padding: "15px",
                }}
              >
                ลงทะเบียนเข้าสู่ระบบ
              </CardHeader>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <FormGroup row>
                    <Label for="username" sm={3}>
                      Username:
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        id="username"
                        name="username"
                        placeholder="ชื่อผู้ใช้งาน"
                        onChange={onChange}
                        required
                        style={{ padding: "10px", fontSize: "1rem" }}
                      />
                      {usernameError && (
                        <small className="text-danger">{usernameError}</small>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="password" sm={3}>
                      Password:
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="on"
                        placeholder="รหัสเข้าใช้งาน"
                        onChange={onChange}
                        required
                        style={{ padding: "10px", fontSize: "1rem" }}
                      />
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button color="link" onClick={handleToForgotPassword}>
                        ลืมรหัสผ่าน?
                      </Button>
                    </Col>
                  </FormGroup>

                  <Col sm={{ size: 8, offset: 2 }}>
                    <Button
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                        padding: "10px",
                        fontSize: "1.2rem",
                      }}
                      block
                      type="submit"
                    >
                      เข้าใช้งาน
                    </Button>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={isModalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Error</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}
