import React, { useState } from "react";
import { Link } from "react-router-dom";

const LoginNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      {/* Toggler for mobile view */}
      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleMenu}
        aria-controls="loginNav"
        aria-expanded={isOpen}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {/* Right-aligned links for login and registration */}
      <div
        className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
        id="loginNav"
      >
        <ul className="navbar-nav ms-auto">
          {/* Login link */}

          {/* Guide link */}
          <li className="nav-item">
            <Link className="nav-link" to="/guide">
              <i className="bi bi-book"></i> คู่มือการใช้งาน
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LoginNavbar;
