import { createSlice } from "@reduxjs/toolkit";
import { getUserList } from "../actions/userAction";

const initialState = {
  userlist: [],
  isLoading: false,
  isReject: false,
  msg: null,
};

const rejectState = {
  userlist: [],
  isLoading: false,
  isReject: true,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // get userlist
      .addCase(getUserList.pending, (state) => {
        state.userlist = [];
        state.isLoading = true;
        state.isReject = false;
        state.msg = "";
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userlist = action.payload;
        state.isLoading = false;
        state.isReject = false;
        state.msg = "";
      })
      .addCase(getUserList.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      });
  },
});

export const { resetUser } = userSlice.actions;
export default userSlice.reducer;
