import React, { useState, useEffect } from "react";
import { useLottie } from "lottie-react";
import "./LoadingScreen.css"; // Import your custom CSS for additional styling
import animationData from "../../config/loading/loading1.json";

const LoadingScreen = ({ resetState }) => {
  const [showResetButton, setShowResetButton] = useState(false);

  const options = {
    animationData: animationData,
    loop: true,
    style: {
      height: 320,
    },
  };

  const { View } = useLottie(options);

  useEffect(() => {
    // Set a timeout of 1 minute (60,000 milliseconds)
    const timeout = setTimeout(() => {
      setShowResetButton(true);
    }, 30000);

    return () => {
      clearTimeout(timeout); // Clear the timeout when the component unmounts
    };
  }, []);

  return (
    <div className="loading-screen">
      {View}
      <div className="loading-text">
        <h2>กำลังโหลดข้อมูล</h2>
        {showResetButton && (
          <>
            <br />
            <button onClick={resetState}>กลับหน้าหลัก</button>
          </>
        )}
      </div>
    </div>
  );
};

export default LoadingScreen;
