import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../actions/authAction";

const MainNavbar = () => {
  const dispatch = useDispatch();
  const { firstname, lastname, token } = useSelector(
    (state) => state.authState
  );
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleLogout = () => {
    const userData = { token };
    dispatch(logout(userData));
  };

  return (
    <>
      {/* Toggler for mobile view */}
      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleMenu}
        aria-controls="navbarNav"
        aria-expanded={isOpen}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {/* Right-aligned links for profile and logout */}
      <div
        className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
        id="navbarNav"
      >
        <ul className="navbar-nav ms-auto">
          {/* Display user profile */}
          {firstname && lastname && (
            <li className="nav-item">
              <Link className="nav-link" to="/profile">
                <i className="bi bi-person-circle"></i>{" "}
                {`${firstname} ${lastname}`}
              </Link>
            </li>
          )}

          {/* Logout button */}
          {token && (
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={handleLogout}
                style={{ textDecoration: "none" }}
              >
                <i className="bi bi-box-arrow-right"></i> ลงชื่อออก
              </button>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default MainNavbar;
