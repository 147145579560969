import React, { Fragment } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { useDispatch } from "react-redux";

const CardService = ({
  token,
  imageMain,
  titleService,
  textService,
  urlService,
  disableService,
}) => {
  const imgStyle = {
    maxWidth: 300,
  };
  const dispatch = useDispatch();

  const onClick = async (e) => {
    console.log(token);
    e.preventDefault();
    await window.open(urlService + `?token=${token.refreshToken}`, "_blank");
  };

  return (
    <Fragment>
      <Card>
        <br />
        <CardImg
          top
          className="align-self-center"
          width="100%"
          src={imageMain}
          style={imgStyle}
          alt="Card image cap"
        />
        <CardBody>
          <CardTitle>{titleService}</CardTitle>
          <CardText>{textService}</CardText>
          <Button
            color="dark"
            onClick={onClick}
            disabled={disableService}
            block
          >
            เข้าใช้งาน
          </Button>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CardService;
