import { Fragment } from "react";
import { Button, Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

export default function NotAllow() {
  const navigate = useNavigate();
  const backToMain = async () => {
    navigate("/");
  };
  return (
    <Fragment>
      <br />
      <Row>
        <Col sm={2}></Col>
        <Col sm={10}>
          <h2>ไม่สามารถเข้าถึงหน้านี้ได้ โปรดกลับหน้าหลัก</h2>
        </Col>
        <Col sm={2}></Col>
      </Row>
      <Row>
        <Col sm={2}></Col>
        <Col sm={10}>
          <Button onClick={backToMain}>กลับหน้าหลัก</Button>
        </Col>
        <Col sm={2}></Col>
      </Row>
      <br />
      <Row></Row>
    </Fragment>
  );
}
