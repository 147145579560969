import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import HistoryTable from "./HistoryTable";

const ServiceHistory = ({ serviceType }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const selectedUserName = params.get("username");
  const { token: authToken, username } = useSelector(
    (state) => state.authState
  );
  const { history } = useSelector((state) => state.serviceState);
  const [loaded, setLoaded] = useState(true);
  const [data, setData] = useState([]);

  // useEffect
  useEffect(() => {
    setData(history);
  }, [history]);

  useEffect(() => {
    // first load
    if (loaded && selectedUserName && serviceType) {
      setLoaded(false);
    }
  }, [loaded, selectedUserName, serviceType]);

  useEffect(() => {
    if (data.length !== 0) {
      // console.log(data);
    }
  }, [data]);

  // const
  const columns = [
    {
      Header: "Time",
      accessor: "timestamp",
    },
    {
      Header: "Position",
      accessor: "position",
    },
    {
      Header: "Active",
      accessor: "active",
    },
    {
      Header: "Detail",
      accessor: "details",
    },
    {
      Header: "Order",
      accessor: "user_order",
    },
  ];

  return (
    <Fragment>
      <Container>
        <Row>
          <h5>
            <u>ประวัติการเปลี่ยนแปลง</u>
          </h5>
        </Row>
      </Container>
      <Container>
        {data.length !== 0 ? (
          <Fragment>
            <HistoryTable columns={columns} data={data} />
          </Fragment>
        ) : (
          <Fragment>
            <p>ไม่พบข้อมูลการเปลี่ยนแปลง</p>
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

export default ServiceHistory;
