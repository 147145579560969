import React, { Fragment, useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import "./Table.css";

// json
import PositionJSON from "./json/PositionJSON.json";
import ServiceJSON from "./json/ServiceJSON.json";

const UserListTable = ({ userList }) => {
  // filter state
  const [userNameFilter, setUserNameFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  const [positionFilter, setPositionFilter] = useState("");
  const [serviceFilter, setServiceFilter] = useState(
    ServiceJSON.map((service) => service.key)
  );

  // Function to toggle the selected service in the filter
  const toggleServiceFilter = (value) => {
    if (serviceFilter.includes(value)) {
      // If the service is already in the filter, remove it
      setServiceFilter(serviceFilter.filter((service) => service !== value));
    } else {
      // If the service is not in the filter, add it
      setServiceFilter([...serviceFilter, value]);
    }
  };

  const clearFilters = () => {
    setUserNameFilter("");
    setNameFilter("");
    setPositionFilter("");
    setServiceFilter(ServiceJSON.map((service) => service.key));
  };

  // column
  const columns = React.useMemo(
    () => [
      {
        Header: "ชื่อผู้ใช้งาน",
        accessor: "username",
      },
      {
        Header: "ชื่อ - นามสกุล",
        accessor: (row) => `${row.firstname} ${row.lastname}`,
      },
      {
        Header: "ตำแหน่ง",
        accessor: "position",
      },
      {
        Header: "สถานะการใช้งาน",
        accessor: "active",
      },
      {
        Header: "บริการ",
        accessor: (row) =>
          row.service.map((service) => service.name).join(", "),
      },
      // {
      //   Header: "ลบผู้ใช้งาน",
      // },
    ],
    []
  );

  // data
  const data = React.useMemo(() => userList, [userList]);

  // combine column and data
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance;

  //   invitation button
  const history = useNavigate();

  const handleInvitationButtonClick = () => {
    history("/invitation");
  };

  return (
    <div className="table-container">
      <Container className="filters">
        <Row>
          {/* Filter section for mobile */}
          <Col xs={12} sm={12} md={12} lg={3} className="order-2 order-lg-1">
            <h5>
              <u>ตัวเลือกการกรอง</u>
            </h5>
            {/* Filter Buasri ID */}
            <Label for="userName">กรองชื่อผู้ใช้งาน</Label>
            <Input
              name="userName"
              type="text"
              value={userNameFilter}
              onChange={(e) => setUserNameFilter(e.target.value)}
              placeholder="กรองชื่อผู้ใช้งาน"
              className="filter-input"
            />
            <br />
            {/* Filter Name */}
            <Label for="name">กรองชื่อ - นามสกุล</Label>
            <Input
              name="name"
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="กรอกชื่อ - นามสกุล"
              className="filter-input"
            />

            <br />
            {/* Filter Position */}
            <Label for="position">กรองตำแหน่ง</Label>
            <Input
              name="position"
              type="select"
              value={positionFilter}
              onChange={(e) => setPositionFilter(e.target.value)}
              className="filter-input"
            >
              <option value="">เลือกทั้งหมด</option>
              {PositionJSON.map((positionMap) => (
                <option key={positionMap.key} value={positionMap.key}>
                  {positionMap.text}
                </option>
              ))}
            </Input>
            <br />
            {/* Filter Service */}
            <FormGroup className="service-filter">
              <Label>กรองบริการ:</Label>
              <div className="service-checkboxes">
                {ServiceJSON.map((serviceMap) => (
                  <div key={serviceMap.key} className="service-checkbox">
                    <Input
                      type="checkbox"
                      value={serviceMap.key}
                      checked={serviceFilter.includes(serviceMap.key)}
                      onChange={(e) => toggleServiceFilter(e.target.value)}
                    />{" "}
                    <Label check>{serviceMap.text}</Label>
                  </div>
                ))}
              </div>
            </FormGroup>

            {/* Button */}
            <Button onClick={() => clearFilters()} className="clear-button">
              ล้างค่าการกรองทั้งหมด
            </Button>
          </Col>

          {/* Table section */}
          <Col xs={12} sm={12} md={12} lg={9} className="order-1 order-lg-2">
            <Row>
              <Col sm="10">
                <Button onClick={handleInvitationButtonClick} color="primary">
                  เชิญผู้ใช้งาน
                </Button>
              </Col>
            </Row>

            {/* Table */}
            <table {...getTableProps()} className="user-list-table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        style={{ textAlign: "center" }}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page
                  .filter(
                    (row) =>
                      row.original.username
                        .toLowerCase()
                        .includes(userNameFilter.toLowerCase()) &&
                      row.original.firstname
                        .toLowerCase()
                        .includes(nameFilter.toLowerCase()) &&
                      (!positionFilter ||
                        row.original.position === positionFilter) &&
                      // Check if the service array is empty or contains at least one active service that matches the filter
                      (row.original.service.length === 0 ||
                        row.original.service.some(
                          (service) =>
                            service.active === "ACTIVE" &&
                            serviceFilter.includes(service.name)
                        ))
                  )
                  .map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        <td>{row.original.username}</td>
                        <td>
                          <Link
                            to={`/service?username=${row.original.username}`} // Pass username as a query parameter
                            className="name-link"
                          >
                            {row.original.firstname} {row.original.lastname}
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {PositionJSON.map((positionMap) =>
                            row.original.position === positionMap.key ? (
                              <span
                                key={positionMap.key}
                                style={{ color: "black" }}
                              >
                                {positionMap.text}
                              </span>
                            ) : null
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {row.original.active === "INACTIVE" ? (
                            <span style={{ color: "red" }}>ปิดการใช้งาน</span>
                          ) : row.original.active === "ACTIVE" ? (
                            <span style={{ color: "green" }}>ใช้งาน</span>
                          ) : (
                            "ปิดการใช้งาน"
                          )}
                        </td>
                        <td>
                          {row.original.service.length === 0
                            ? "No Services"
                            : row.original.service
                                .filter(
                                  (service) => service.active === "ACTIVE"
                                )
                                .map((service) =>
                                  service.name === "e-finance"
                                    ? "E-Finance"
                                    : "Unknown Service"
                                )
                                .join(", ")}
                        </td>
                        {/* <td style={{ textAlign: "center" }}>
                          Delete Button
                          <DeleteUserButton
                            username={row.original.username}
                            firstname={row.original.firstname}
                            lastname={row.original.lastname}
                          />
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="pagination">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <span>
                Page{" "}
                <strong>
                  {state.pageIndex + 1} of {state.pageCount}
                </strong>{" "}
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserListTable;
