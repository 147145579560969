import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../config/config";
import apiHelper from "../config/helper/apiHelper";
import axios from "axios";

const HOST_API = config.HOST_API;

// update service
export const updateService = createAsyncThunk(
  "service/update",
  async (newData, { rejectWithValue }) => {
    try {
      const accessToken = newData.accessToken;
      const refreshToken = newData.refreshToken;
      const username = newData.username;
      const email = newData.email;
      const service = newData.service;
      const active = newData.active;
      const position = newData.position;
      const user_order = newData.user_order;
      const url =
        service === "e-service"
          ? `${HOST_API}/api/admin/update-service`
          : service === "e-finance"
          ? `${HOST_API}/api/admin/update-finance`
          : "";
      const method =
        service === "e-service" ? "POST" : service === "e-finance" ? "PUT" : "";

      const data = JSON.stringify({
        refreshToken,
        username,
        email,
        service,
        active,
        position,
        user_order,
      });

      console.log("body: " + data);

      const headers = {
        "Content-Type": "application/json",
        Authorization: accessToken,
      };

      const result = await apiHelper(url, method, data, headers);
      return result;
    } catch (error) {
      // console.log("error: " + error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);

// get history
export const getHistory = createAsyncThunk(
  "service/history",
  async (data, { rejectWithValue }) => {
    try {
      const accessToken = data.accessToken;
      const refreshToken = data.refreshToken;
      const username = data.username;
      const service = data.service;

      const url = `${HOST_API}/api/admin/permissionhistory`;
      const method = "POST";
      const body = JSON.stringify({
        refreshToken,
        username,
        service,
      });
      const headers = {
        "Content-Type": "application/json",
        Authorization: accessToken,
      };

      // console.log("data: " + JSON.stringify(username));

      const result = await apiHelper(url, method, body, headers);
      return result;
    } catch (error) {
      // console.log("error: " + error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);
