import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Row, Col, Container, Breadcrumb, BreadcrumbItem } from "reactstrap";

// component
import NotAllow from "../Error/notAllow";
import UserListTable from "./Table";

// action
import { getUserList } from "../../actions/userAction";

// reducer
import { resetService } from "../../reducers/serviceReducer";

export default function UserList() {
  const dispatch = useDispatch();
  const {
    token: authToken,
    username,
    position,
  } = useSelector((state) => state.authState);

  const { userlist: userList } = useSelector((state) => state.userState);

  // start or refresh page
  const [startPage, setStartPage] = useState(true);
  // open page
  // get userlist data
  useEffect(() => {
    if (username && authToken && startPage) {
      if (authToken.accessToken && authToken.refreshToken) {
        const data = {
          username,
          accessToken: authToken.accessToken,
          refreshToken: authToken.refreshToken,
        };

        dispatch(getUserList(data));

        // reset
        dispatch(resetService());
        setStartPage(false);
      }
    }
  }, [username, authToken]);

  // control content
  let content;

  if (position && position === "ADMIN") {
    if (userList) {
      content = (
        <Fragment>
          <Container>
            <br />
            <h2 className="userlist-header">รายชื่อผู้ใช้งาน</h2>
          </Container>
          <Container>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/">หน้าหลัก</a>
              </BreadcrumbItem>
              <BreadcrumbItem active>รายชื่อผู้ใช้งาน</BreadcrumbItem>
            </Breadcrumb>
          </Container>
          <Container>
            <Row>
              {/* list table */}
              <UserListTable userList={userList} />
            </Row>
          </Container>
        </Fragment>
      );
    } else {
      content = (
        <Fragment>
          <Container>
            <br />
            <h2 className="userlist-header">รายชื่อผู้ใช้งาน</h2>
          </Container>
          <Container>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/">หน้าหลัก</a>
              </BreadcrumbItem>
              <BreadcrumbItem active>รายชื่อผู้ใช้งาน</BreadcrumbItem>
            </Breadcrumb>
          </Container>
          <Container>
            <Row>เกิดข้อผิดพลาด ไม่พบผู้ใช้งาน</Row>
          </Container>
        </Fragment>
      );
    }
  } else {
    content = <NotAllow />;
  }

  return content;
}
