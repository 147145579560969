import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { forgotPassword } from "../../actions/authAction";
import { resetForgotPassword } from "../../reducers/authReducer";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { forgotPassword: forgotPState } = useSelector(
    (state) => state.authState
  );
  const [startForm, setStartForm] = useState(true);

  useEffect(() => {
    if (startForm) {
      // reset
      //   dispatch(resetForgotPassword());
      setStartForm(false);
    }
  }, [startForm]);

  useEffect(() => {
    if (forgotPState) {
      // Navigate to "/"
      history("/");
    }
  }, [forgotPState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { email };
    dispatch(forgotPassword(data));
    setSubmitted(true);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <CardHeader>Forgot Password</CardHeader>
            <CardBody>
              {submitted ? (
                forgotPState ? (
                  <p>
                    If the email provided is associated with an account, you
                    will receive a password reset link shortly.
                    {forgotPState && " ส่งอีเมลสำเร็จ โปรดตรวจสอบอีเมลของคุณ"}
                  </p>
                ) : (
                  <p>
                    If the email provided is associated with an account, you
                    will receive a password reset link shortly.
                  </p>
                )
              ) : (
                <FormGroup>
                  <Label for="email">Email:</Label>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              )}
              <Button
                color="dark"
                onClick={handleSubmit}
                disabled={submitted}
                block
              >
                {submitted
                  ? forgotPState
                    ? "ส่งอีเมลสำเร็จ"
                    : "กำลังส่งอีเมล..."
                  : "ยืนยันการส่งอีเมล"}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
