import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../config/config";
import apiHelper from "../config/helper/apiHelper";
import axios from "axios";
import {
  setSuccessRegisterAdmin,
  setSuccessVerifyUser,
} from "../reducers/authReducer";

const HOST_API = config.HOST_API;

export const registerAdmin = createAsyncThunk(
  "auth/register-admin",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const requestBody = {
        userData: data,
      };
      const body = JSON.stringify(requestBody);

      const response = await axios.post(
        `${HOST_API}/api/register/admin`,
        body,
        { headers }
      );

      // Check if the response contains an error property
      if (response.data && response.data.error) {
        const errorM = response.data.error.errorMessage;
        dispatch(setSuccessRegisterAdmin(false));
        return rejectWithValue(errorM);
      } else {
        return response.data;
      }
    } catch (error) {
      // console.log(error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);

// invitation user by email
export const sendInvitations = createAsyncThunk(
  "auth/send-invitation",
  async (data, { rejectWithValue }) => {
    try {
      const accessToken = data.token.accessToken;
      const refreshToken = data.token.refreshToken;
      const invitations = data.data.invitations;

      const url = `${HOST_API}/api/invite/send`;
      const method = "POST";
      const body = JSON.stringify({
        refreshToken,
        invitations,
      });

      // console.log(body);
      const headers = {
        "Content-Type": "application/json",
        Authorization: accessToken,
      };

      const result = await apiHelper(url, method, body, headers);
      // console.log(result);
      return result;
    } catch (error) {
      // console.log(error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);

// verify user
export const verifyUser = createAsyncThunk(
  "auth/verify-user",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(data);
      // console.log("body: " + body);

      const response = await axios.post(`${HOST_API}/api/invite/accept`, body, {
        headers,
      });

      // Check if the response contains an error property
      if (response.data && response.data.error) {
        const errorM = response.data.error.errorMessage;
        dispatch(setSuccessVerifyUser(false));
        return rejectWithValue(errorM);
      } else {
        return response.data;
      }
    } catch (error) {
      // console.log(error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);
