import { createSlice } from "@reduxjs/toolkit";
import { updateService, getHistory } from "../actions/serviceAction";

const initialState = {
  history: [],
  serviceUpdateStatus: false,
  isLoading: false,
  isError: false,
  errorMessage: "",
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    resetService: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateService.pending, (state) => {
        state.serviceUpdateStatus = false;
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateService.fulfilled, (state) => {
        state.serviceUpdateStatus = true;
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateService.rejected, (state, action) => {
        state.history = [];
        state.serviceUpdateStatus = false;
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getHistory.pending, (state) => {
        state.history = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        const dataHistory = action.payload
          ? action.payload[0]?.logEntries || []
          : [];
        state.history = dataHistory;
        state.isLoading = false;
        state.isReject = false;
        state.errorMessage = null;
      })
      .addCase(getHistory.rejected, (state) => {
        state.history = [];
        state.serviceUpdateStatus = false;
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = "";
      });
  },
});

export const { resetService } = serviceSlice.actions;
export default serviceSlice.reducer;
