import { Routes, Route, Navigate } from "react-router-dom";

// components
import Main from "./Main/Main";
import Login from "./Login/Login";
import ForgotPassword from "./Login/ForgotPassword";
import ResetPassword from "./Login/ResetPassword";
import RegisterAdmin from "./Register/RegisterAdmin";
import VerifyUser from "./Register/VerifyUser";
import Profile from "./Profile/Profile";
import UserList from "./User/UserList";
import Invitation from "./User/Invitation";
import ServiceList from "./ServiceList/ServiceList";

// Main routes handler component
const AppRoutes = ({
  authToken,
  active,
  registerAdmin,
  position,
  username,
}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          authToken && active === "ACTIVE" && !registerAdmin ? (
            <Main />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/userlist"
        element={
          authToken && active === "ACTIVE" && !registerAdmin ? (
            <UserList />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/invitation"
        element={
          authToken &&
          position === "ADMIN" &&
          active === "ACTIVE" &&
          !registerAdmin ? (
            <Invitation />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/service"
        element={
          authToken &&
          position === "ADMIN" &&
          active === "ACTIVE" &&
          !registerAdmin ? (
            <ServiceList />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/login"
        element={
          !registerAdmin ? (
            authToken && active === "ACTIVE" ? (
              <Navigate to="/" />
            ) : (
              <Login />
            )
          ) : (
            <Navigate to="/register-admin" />
          )
        }
      />
      <Route
        path="/profile"
        element={
          authToken && active === "ACTIVE" && !registerAdmin ? (
            <Profile />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/forgot-password"
        element={
          !registerAdmin ? (
            <ForgotPassword />
          ) : (
            <Navigate to="/register-admin" />
          )
        }
      />
      <Route
        path="/reset-password"
        element={
          !registerAdmin ? <ResetPassword /> : <Navigate to="/register-admin" />
        }
      />
      <Route
        path="/register-admin"
        element={registerAdmin ? <RegisterAdmin /> : <Navigate to="/" />}
      />
      <Route
        path="/verify"
        element={
          !registerAdmin ? (
            !username ? (
              <VerifyUser />
            ) : (
              <Navigate to="/" />
            )
          ) : (
            <RegisterAdmin />
          )
        }
      />
    </Routes>
  );
};

export default AppRoutes;
