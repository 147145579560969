import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Container, Breadcrumb, BreadcrumbItem, Row, Col } from "reactstrap";
import "./Profile.css";

// json
import academicData from "../../config/json/academicStanding.json";
import positionsData from "../../config/json/positions.json";

export default function Profile() {
  const authUser = useSelector((state) => state.authState);
  // Function to map position value to name
  const mapPositionToName = (position, service) => {
    const servicePositions = positionsData[service];
    const mappedPosition = servicePositions.find(
      (pos) => pos.value === position
    );
    return mappedPosition ? mappedPosition.name : "ไม่มี";
  };

  const renderServiceItems = () => {
    return authUser.service
      ? authUser.service.map((service) =>
          service.active === "ACTIVE" ? (
            <li key={service._id} className="service-item">
              <strong>ชื่อบริการ:</strong> {service.name}
              <br />
              <strong>ตำแหน่ง:</strong>{" "}
              {positionsData[service.name]
                ? mapPositionToName(service.position, service.name)
                : service.position}
            </li>
          ) : null
        )
      : null;
  };
  return (
    <Fragment>
      <br />
      <Container>
        <h2 className="profile-header">รายละเอียดผู้ใช้งาน</h2>
      </Container>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/">หน้าหลัก</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>รายละเอียดผู้ใช้งาน</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <br />
      <Container className="profile-container">
        <Row>
          <Col>
            <h4 className="profile-subheader">ข้อมูลส่วนตัว</h4>
            <p>
              <strong>ชื่อ-นามสกุล:</strong>{" "}
              {`${authUser.title} ${authUser.firstname} ${authUser.lastname}`}
            </p>
            <p>
              <strong>ตำแหน่ง:</strong>{" "}
              {mapPositionToName(authUser.position, "e-service")}
            </p>
            <p>
              <strong>ตำแหน่งทางวิชาการ:</strong>{" "}
              {academicData.academic_standing.find(
                (standing) => standing.value === authUser.academicStanding
              )?.name_th || "ไม่มี"}
            </p>
            <p>
              <strong>Email:</strong> {authUser.email}
            </p>
            <p>
              <strong>เบอร์ติดต่อ:</strong> {authUser.phone}
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="profile-container">
        <Row>
          <Col>
            <h4 className="profile-subheader">บริการที่เปิดใช้งาน</h4>
            <ul className="service-list">{renderServiceItems()}</ul>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
