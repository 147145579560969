import React from "react";
import { useSelector } from "react-redux";
import LoginNavbar from "./Login/LoginNavbar";
import MainNavbar from "./Main/MainNavbar";

import "./Navbar.css";

export default function Navbar() {
  const { username, token } = useSelector((state) => state.authState);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm sticky-top">
      <div className="container-fluid">
        {/* Brand/logo on the left */}
        <a className="navbar-brand" href="/">
          ศูนย์บริการออนไลน์ โรงเรียนวัดพลงช้างเผือก
        </a>

        {/* Conditional Navbar: Show MainNavbar for logged in users, LoginNavbar for guests */}
        {username && token ? <MainNavbar /> : <LoginNavbar />}
      </div>
    </nav>
  );
}
