const dev = {
  Version: "dev v.1.0",
  HOST_API: "https://service.watplong.ac.th",
  Finance_Client: "http://localhost:3311",
};

const production = {
  Version: "production v.1.0",
  HOST_API: "https://service.watplong.ac.th",
  Finance_Client: "https://finance.watplong.ac.th",
};

export const config = process.env.NODE_ENV === "development" ? dev : production;
