import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// action
import { sendInvitations } from "../../actions/registerAction";

// reducer
import { resetSendEmail } from "../../reducers/authReducer";

const positionsForEFINANCE = [
  { label: "ผู้ดูแลระบบ", value: "ADMIN" },
  { label: "ฝ่ายบริหาร", value: "MANAGEMENT" },
  { label: "หัวหน้าฝ่ายการเงิน", value: "HEADFINANCE" },
  { label: "ฝ่ายการเงิน", value: "FINANCE" },
  { label: "ฝ่ายแผน", value: "PLANNER" },
  { label: "ฝ่ายพัสดุ", value: "PROCUREMENT" },
  { label: "เจ้าหน้าที่", value: "OFFICER" },
  { label: "ครู, อาจารย์", value: "TEACHER" },
  // ... (other positions)
];

const Invitation = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { token, successInvitation } = useSelector((state) => state.authState);

  const [invitations, setInvitations] = useState([
    { email: "", service: "", position: "" }, // Default invitation field
  ]);

  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const onChange = (index, key, value) => {
    const updatedInvitations = [...invitations];
    // Trim the value if it's the email field
    updatedInvitations[index][key] = key === "email" ? value.trim() : value;
    setInvitations(updatedInvitations);
  };

  const addInvitationField = () => {
    setInvitations([...invitations, { email: "", service: "", position: "" }]);
  };

  const removeInvitationField = (index) => {
    const updatedInvitations = [...invitations];
    updatedInvitations.splice(index, 1);
    setInvitations(updatedInvitations);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // Open the confirmation modal
    toggleModal();
  };

  const confirmSubmission = () => {
    // setData using action
    const data = {
      token,
      data: mapToDesiredFormat(invitations),
    };
    // Send invitations using the action
    dispatch(sendInvitations(data));

    // Clear form fields after submission
    setInvitations([{ email: "", service: "", position: "" }]);

    // Close the confirmation modal
    toggleModal();
  };

  // Helper function to map form data to the desired JSON format
  const mapToDesiredFormat = (invitations) => {
    return {
      invitations: invitations.map((invitation) => ({
        email: invitation.email,
        servicePermissions: [
          {
            service: invitation.service,
            position: invitation.position,
          },
        ],
      })),
    };
  };

  // success send email
  useEffect(() => {
    if (successInvitation) {
      const alertSuccess = async () => {
        await alert("Invitations sent successfully!");
        await dispatch(resetSendEmail());
        await history("/");
      };
      alertSuccess();
    }
  }, [successInvitation]);

  return (
    <Fragment>
      <Container>
        <Row>
          <Breadcrumb>
            <BreadcrumbItem>Home</BreadcrumbItem>
            <BreadcrumbItem active>Invitation Form</BreadcrumbItem>
          </Breadcrumb>
        </Row>
        <Row>
          <Col xs="12" md={{ size: 6, offset: 3 }}>
            <Form onSubmit={onSubmit}>
              {invitations.map((invitation, index) => (
                <FormGroup key={index}>
                  <Label for={`email-${index}`}>Email</Label>
                  <Input
                    type="email"
                    name={`email-${index}`}
                    id={`email-${index}`}
                    placeholder="Enter email"
                    value={invitation.email}
                    onChange={(e) => onChange(index, "email", e.target.value)}
                    required
                  />
                  <FormGroup check className="mt-2">
                    <Label check>
                      <Input
                        type="checkbox"
                        id={`efinance-${index}`}
                        checked={invitation.service === "e-finance"}
                        onChange={(e) => {
                          onChange(
                            index,
                            "service",
                            e.target.checked ? "e-finance" : ""
                          );
                          onChange(index, "position", ""); // Reset position when unchecking the checkbox
                        }}
                      />
                      E-Finance
                    </Label>
                  </FormGroup>
                  {invitation.service === "e-finance" && (
                    <Fragment>
                      <Label for={`position-${index}`} className="mt-2">
                        Position
                      </Label>
                      <Input
                        type="select"
                        name={`position-${index}`}
                        id={`position-${index}`}
                        value={invitation.position}
                        onChange={(e) =>
                          onChange(index, "position", e.target.value)
                        }
                      >
                        <option value="" disabled>
                          Select Position
                        </option>
                        {positionsForEFINANCE.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </Input>
                    </Fragment>
                  )}
                  {index > 0 && (
                    <Button
                      color="danger"
                      size="sm"
                      className="mt-2"
                      onClick={() => removeInvitationField(index)}
                    >
                      Remove
                    </Button>
                  )}
                </FormGroup>
              ))}
              <Button color="primary" type="submit">
                Send Invitations
              </Button>{" "}
              <Button
                color="success"
                className="ml-2"
                onClick={addInvitationField}
              >
                +
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirm Submission</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to send these invitations?</p>
          <ul>
            {invitations.map((invitation, index) => (
              <li key={index}>
                <strong>Email:</strong> {invitation.email} <br />
                <strong>Service:</strong> {invitation.service} <br />
                {invitation.service === "e-finance" && (
                  <Fragment>
                    <strong>Position:</strong>{" "}
                    {
                      positionsForEFINANCE.find(
                        (pos) => pos.value === invitation.position
                      )?.label
                    }{" "}
                    <br />
                  </Fragment>
                )}
                <br />
              </li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={confirmSubmission}>
            Confirm
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Invitation;
