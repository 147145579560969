import axios from "axios";
import { config } from "../config";
const HOST_API = config.HOST_API;
const apiHelper = async (url, method, data, headers) => {
  try {
    // console.log(data);
    const response = await axios({ url, method, data, headers });
    // const response = await axios.${method}(url, data, { headers });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.errorMessage === "access token expired"
    ) {
      //   console.log(error.response.data.errorMessage);
      const checkRefreshedTokens = await refreshTokens(data, headers);
      if (checkRefreshedTokens) {
        console.log(checkRefreshedTokens);
        // Retry the original request with the new access token
        const newHeaders = {
          "Content-Type": "application/json",
          Authorization: `${checkRefreshedTokens.accessToken}`,
        };
        const newData = {
          ...data,
          refreshToken: checkRefreshedTokens.refreshToken,
        };
        // console.log("newHeader: " + JSON.stringify(newHeaders));
        // console.log("newData: " + newData);
        const retryResponse = await axios({
          url,
          method,
          data: newData,
          headers: newHeaders,
        });
        return retryResponse.data;
      }
    } else {
      return error;
    }
    // return { error: error.response ? error.response.data : "Unknown error" };
  }
};

const refreshTokens = async (data, headers) => {
  try {
    console.log("headers: " + JSON.stringify(headers));
    const response = await axios.post(
      `${HOST_API}/api/auth/access-token`,
      data,
      { headers }
    );
    // console.log(response.data);
    return response.data.tokens;
  } catch (error) {
    console.error("Error refreshing tokens:", error.message);
    return error;
  }
};

export default apiHelper;
